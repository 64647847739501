import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';
import { generateKebabCaseId } from 'utilities/index';

export const copyUrl = (id, formatMessage) => {
    const prefix = id.startsWith('#') ? '' : '#';
    const currentUrl = `${window.location.origin}${window.location.pathname}${prefix}${id}`;

    navigator.clipboard.writeText(currentUrl).then(
        () => {
            toast.success(
                formatMessage({
                    id: 'general.copied',
                }),
                {
                    position: 'bottom-center',
                    duration: 1500,
                },
            );
        },
        () => {
            toast.error(
                formatMessage({
                    id: 'general.copyFailed',
                }),
                {
                    position: 'bottom-center',
                    duration: 1500,
                },
            );
        },
    );
};
export const useTranslatedTitle = (title, prefix = 'h') => {
    const { formatMessage } = useIntl();
    if (typeof title !== 'string' || !title.trim()) {
        // Invalid title: expected a non-empty string
        return { translatedTitle: '', kebabCaseId: null };
    }

    let translatedTitle;
    try {
        translatedTitle = formatMessage({ id: title });
        const translatedJsonTitle = translatedTitle.props?.children[0];

        if (
            typeof translatedTitle !== 'string' &&
            typeof translatedJsonTitle !== 'string'
        ) {
            throw new TypeError(`Translation for "${title}" is not a string.`);
        }
    } catch {
        // Failed to translate title
        return { translatedTitle: '', kebabCaseId: null };
    }

    const kebabCaseId = `${prefix}-${generateKebabCaseId(title)}`;

    return {
        translatedTitle,
        kebabCaseId,
        handleCopyUrl: () => copyUrl(kebabCaseId, formatMessage),
    };
};
